import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { getUserProfileApi, postUserArticleApi } from "../../network/api";
import { toast } from "react-toastify";
const PostArticlePage = () => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const [submissionCount, setSubmissionCount] = useState(0);
  const [articleCount, setArticleCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [hideForm, setHideForm] = useState(false);

  // Fetch user's submission count on component mount
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await getUserProfileApi();
        if (response.status === 200 && response.data.status === 200) {
          setArticleCount(response.data.data.articlesPosted)
          if(response.data.data.articlesPosted>=4){
            setHideForm(true)
          }
        }
       
      } catch (e) {
        console.error("Fetch Error:", e);
        toast.error(e.message);
      }
    };

    fetchProfile();
  }, []);

  const handleContentChange = (content) => {
    setValue("content", content); 
  };

  const onSubmit = async (data) => {
    
    try {
      const submitData = new FormData();
      if (hideForm) return;
      Object.keys(data).forEach((key) => {
        if (key === "image") {
          submitData.append(key, data.image[0]); 
        } else {
          submitData.append(key, data[key]);
        }
      });
      setIsLoading(true);
      const response = await postUserArticleApi(submitData);
      if(articleCount+1>=4)
        setHideForm(true)
      if (response.status === 200 || response.data.status === 200) {
        toast.success(response.data.message);
        reset(); 
        setSubmissionCount(submissionCount + 1); // Increment count on success

        // Hide the form if the count reaches 4 after submission
        if (submissionCount + 1 >= 4) {
          setHideForm(true);
        }
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error sending message:", error);
      toast.error(error.message || "An error occurred while sending the article.");
    }finally {
      setIsLoading(false);
    }
  };

  const validateFile = (file) => {
    const validFileTypes = ["image/jpeg", "image/png", "image/jpg"];
    const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB

    if (!file[0]) return "File is required.";

    if (!validFileTypes.includes(file[0].type)) {
      return "Invalid file type. Please upload a JPEG or PNG image.";
    }

    if (file[0].size > maxSizeInBytes) {
      return "File size exceeds the 2 MB limit. Please upload a smaller image.";
    }

    return true; 
  };
  // Render message if form is hidden
  if (hideForm) {
    return <div className="alert alert-success">You have submitted the maximum number of articles allowed this month.</div>;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-floating mb-3">
        <h5 className="mt-4 mb-4">Article Details</h5>

        <div className="form-floating mb-3">
          <input
            type="text"
            className={`form-control ${errors.title ? 'is-invalid' : ''}`}
            id="titleInput"
            {...register("title", { required: "Title is required." })}
            placeholder="Enter title"
          />
          <label htmlFor="titleInput">Title<span className="text-danger">*</span></label>
          {errors.title && <div className="error-text">{errors.title.message}</div>}
        </div>

        <div className="form-floating">
          <textarea
            className={`form-control ${errors.brief ? 'is-invalid' : ''}`}
            id="briefTextarea"
            {...register("brief", { required: "Brief is required." })}
            placeholder="Leave a comment here"
          ></textarea>
          <label htmlFor="briefTextarea">Brief<span className="text-danger">*</span></label>
          {errors.brief && <div className="error-text">{errors.brief.message}</div>}
        </div>

        <div className="input-group mt-3">
          <label className="input-group-text" htmlFor="inputGroupFile01">
            Upload Image
          </label>
          <input
            type="file"
            className={`form-control ${errors.image ? 'is-invalid' : ''}`}
            id="inputGroupFile01"
            {...register("image", { validate: validateFile })}
          />
          {errors.image && <div className="error-text">{errors.image.message}</div>}
        </div>

        <div className="form-floating mt-3 ">
          <div className="mt-1">
            <label htmlFor="content">Content <span className="text-danger">*</span></label>
            <ReactQuill
              className="content"
              theme="snow"
              value={watch("content")}
              onChange={handleContentChange}
            />
          </div>
        </div>

        <div className="form-floating mt-3">
          <select
            className={`form-select pt-2 ${errors.hashtag ? 'is-invalid' : ''}`}
            id="hashtagSelect"
            {...register("hashtag", { required: "Hashtag is required." })}
          >
            <option disabled value="">
              Hashtag
            </option>
            <option value="technology">Technology</option>
            <option value="media">Media</option>
            <option value="education">Education</option>
            <option value="business">Business</option>
            <option value="art-culture">Art & Culture</option>
            <option value="sports">Sports</option>
            <option value="policy">Policy</option>
            <option value="management">Management</option>
            <option value="environment">Environment</option>
            <option value="humanities">Humanities</option>
            <option value="wellness">Wellness</option>
          </select>
          {errors.hashtag && <div className="error-text">{errors.hashtag.message}</div>}
        </div>
        <div className="form-floating mt-3 mb-3">
          <input
            type="text"
            className={`form-control ${errors.metaKeywords ? 'is-invalid' : ''}`}
            id="metaKeywordsInput"
            {...register("metaKeywords", { required: "This field is required."})}
            placeholder="Enter keywords"
          />
          <label htmlFor="metaKeywordsInput">Meta Keywords <span className="text-danger">*</span></label>
          {errors.metaKeywords && <div className="invalid-feedback error-text">{errors.metaKeywords.message}</div>}
        </div>
        <div className="form-check mt-4">
  <input
    className={`form-check-input ${errors.terms ? 'is-invalid' : ''}`}
    type="checkbox"
    id="termsCheck"
    {...register("terms", {
      required: "You must agree to the Terms & Conditions.",
    })}
  />
 <label
  className="form-check-label"
  style={{ textAlign: "justify" }}
  htmlFor="termsCheck"
>
  <strong>Terms & Conditions:</strong> <br />
  The undersigned author affirms that the content of the submitted Article/Blog/Post
  is original and free from any plagiarized material. All references, data, and
  citations included therein are accurate and duly attributed to their respective
  sources. The Article/Blog/Post does not contain any content that is defamatory,
  abusive, or otherwise in violation of applicable laws. “The Maximus Report” retains
  the right to suspend or terminate the submission or accessibility of the
  Article/Blog/Post at its sole discretion, with or without cause, and without prior
  notice. Furthermore, “The Maximus Report” reserves the right to amend these Terms
  and Conditions at any time, with the latest version being published on its platform.
  By continuing to submit or host the Article/Blog/Post on the platform, the author
  signifies their acceptance of the updated Terms and Conditions. Submission of the
  Article/Blog/Post constitutes acknowledgment and agreement to these Terms and
  Conditions by the author.
</label>

  {errors.terms && (
    <div className="invalid-feedback error-text">{errors.terms.message}</div>
  )}
</div>


        <div className="mt-4 mb-3">
          {isLoading ? (
                <div className="spinner-border" role="status">
                  <span className="sr-only"></span>
                </div>
              ) : (
                <button type="submit" className="btn btn-primary">Submit</button>
              )}
        </div>
      </div>
    </form>
  );
};

export default PostArticlePage;
