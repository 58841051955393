import { useSelector } from "react-redux";
import { Link, Navigate, Route, Routes, useLocation } from "react-router-dom";
import PostArticlePage from "./PostArticlePage";
import StudentProfilePage from "./StudentProfilePage";
import ResetPasswordPage from "./ResetPasswordPage";
import StudentArticlePage from "./StudentArticlePage";
import Researchpaper from "./Researchpaper";
import PostResearchpage from "./PostResearchpaper";
import ResetPassword from "./ResetPassword";

const navLinks = [
  {path:'/student-corner/post-article',title:'Post Article'},
  {path:'/student-corner/my-articles',title:'My Articles'},
  {path:'/student-corner/research-paper', title:'My Research Paper'},
  {path:'/student-corner/post-researchpage', title:'Post Research paper'},
  
]
const StudentHomeContainer = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  console.log(currentPath);
  const auth = useSelector((state) => state.auth);
  if(auth.token == null){
    return <Navigate to={'/student-corner'}/>
  }
  return (
    <>
      <div className="container-fluid mt-3 mt-lg-0">
        <ul className="nav nav-tabs justify-content-end">
          {navLinks.map((item,index)=>{
            return(<li className="nav-item" key={index}>
              <Link to={item.path} className={"nav-link" + (item.path == currentPath ? ' active':'') }>{item.title}</Link>
            </li>)
          })}
          <li className="nav-item">
            <Link to={'/student-corner'} className={"nav-link" + ('/student-corner' == currentPath ? ' active':'') }><i className="bx bx-user"></i> {auth.student?.name ?? ''}</Link>
          </li>
        </ul>
        <div className="tab-content mt-3">
            <Routes>
            <Route path="/" element={<StudentProfilePage />} />
            <Route path="my-articles" element={<StudentArticlePage />} />
            <Route path="post-article" element={<PostArticlePage />} />
            {/* <Route path="forgot-password" element={<ResetPasswordPage />} /> */}
            <Route path="research-paper" element={<Researchpaper />} />
            <Route path="post-researchpage" element={<PostResearchpage />} />
            {/* <Route path="/reset-password" element={<ResetPassword/>} /> */}
            
            </Routes>
        </div>
      </div>
    </>
  );
};

export default StudentHomeContainer;
