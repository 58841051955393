import { ENDPOINTS } from "./networkConfig";
import axios from "axios";

function commonHeaders() {
  return {
    Authorization: 'Bearer ' + getAuthToken(),
    "Content-Type":"multipart/form-data",
  }
}

function getAuthToken() {
  if (localStorage.getItem('authState')) {
    try {
      let token = JSON.parse(localStorage.getItem('authState')).token
      return token
    } catch (err) {
      console.log('Error:', err)
      return ''
    }
  }
  return ''
}


export const loginApi = async (loginDetails) => {
  // console.warn("Request:", loginDetails);
  let response = await axios({
    method: "post",
    url: ENDPOINTS.login,
    data: loginDetails,
  });
  return response;
};


export const googleLoginApi = async (credToken) => {
  // console.warn("Request:", loginDetails);
  let response = await axios({
    method: "post",
    url: ENDPOINTS.googleLogin,
    data: {credToken:credToken},
  });
  return response;
};

export const registerApi = async (registerDetails) => {
  // console.warn("Request:", loginDetails);
  let response = await axios({
    method: "post",
    url: ENDPOINTS.register,
    data: registerDetails,
  });
  return response;
};

export const getWebsiteDataApi = async () => {
  let response = await axios({
    method: "get",
    url: ENDPOINTS.getWebsiteData,
  });
  return response;
};

export const contactUsDataApi = async (contactDetails) => {
  let response = await axios({
    method: "post",
    url: ENDPOINTS.contactUsData,
    data: contactDetails,
  });
  return response;
};
export const subscribeNewsletterDataApi = async (subscribeDetails) => {
  // console.warn("Request:", loginDetails);
  let response = await axios({
    method: "post",
    url: ENDPOINTS.subscribeNewsletterData,
    data: subscribeDetails,
  });
  return response;
};
export const postYourStoryApi = async (storyDetails) => {
  let response = await axios({
    method: "post",
    url: ENDPOINTS.postYourStory,
    data: storyDetails,
  });
  return response;
};
export const getAllArticleApi = async (page,hashtag,title) => {
  let response = await axios({
    method: "get",
    url: `${ENDPOINTS.getAllArticle}?hashtag=${hashtag}&limit=10&page=${page}&title=${title}`,
  });
  return response;
};

export const getHighlighArticleApi = async (hashtag) => {
  let response = await axios({
    method: "get",
    url: `${ENDPOINTS.getHighlighArticle}?hashtag=${hashtag}&limit=10&page=1`,
  });
  return response;
};

export const getArticleByIdApi = async (id) => {
  let response = await axios({
    method: "get",
    url: `${ENDPOINTS.getArticleById}/${id}`,
  });
  return response;
};


export const updateUserProfileApi = async (formData) => {
  let response = await axios({
    method: "post",
    url: ENDPOINTS.updateUserProfile,
    data:formData,
    headers: commonHeaders()
  });
  return response;
};


export const createOrganizationDetailsApi = async (formData) => {
  let response = await axios({
    method: "post",
    url: ENDPOINTS.createOrganizationDetails,
    data:formData,
    headers: commonHeaders()
  });
  return response;
};




export const getTakeALookApi= async (hashtag) => {
  let response = await axios({
    method: "get",
    url: `${ENDPOINTS.getTakeALook}?hashtag=${hashtag}&limit=10&page=1`,
  });
  return response;
};
export const postUserArticleApi = async (postUserArticleDetails) => {

  let response = await axios({
    method: "post",
    url: ENDPOINTS.postUserArticle,
    data: postUserArticleDetails,
    headers: commonHeaders(),

  });
  return response;
};



export const getUserProfileApi= async () => {
  let response = await axios({
    method: "get",
    url: ENDPOINTS.getUserProfile,
    headers: commonHeaders(),
  });
  return response;
};
export const userArticleViewApi = async () => {
  let response = await axios({
    method: "get",
    url: ENDPOINTS.userArticleView,
    headers: commonHeaders(),
  });
  return response;
};


export const getPageDataApi = async (type) => {
  let response = await axios({
    method: "get",
    url: `${ENDPOINTS.getPageData}/${type}`,
  });
  return response;
};

export const postResearchPaperApi = async (postResearchPaperDetails) => {

  let response = await axios({
    method: "post",
    url: ENDPOINTS.postResearchPaper,
    data: postResearchPaperDetails,
    headers: commonHeaders(),

  });
  return response;
};


export const getResearchPaperByUserApi = async () => {
  let response = await axios({
    method: "get",
    url: `${ENDPOINTS.getResearchPaperByUser}`,
    headers: commonHeaders(),
  });
  return response;
}

export const getApprovedResearchOnWebApi = async (page, research_paper_title) => {
  // console.log("API call with title:", title); // Check the title passed
  let response = await axios({
    method: "get",
    url: `${ENDPOINTS.getApprovedResearchOnWeb}?page=${page}&limit=10&research_paper_title=${research_paper_title}`,
    headers: commonHeaders(),
  });
  return response;
};


export const updateArticleByUserApi = async (id,data) => {
  let response = await axios({
    method: "post",
    url: `${ENDPOINTS.updateArticleByUser}/${id}`,
    data: data,
    headers: commonHeaders(),
  });
  return response;
};


export const forgetPasswordApi = async (email) => {
  let response = await axios({
    method: "post",
    url: `${ENDPOINTS.forgetPassword}`,
    data: email,
  });
  return response;
};

export const ResetPasswordApi = async (data) => {
  let response = await axios({
    method: "post",
    url: `${ENDPOINTS.ResetPassword}`,
    data: data,
  });
  return response;
};


export const getActiveAdsApi = async () => {
  let response = await axios({
    method: "get",
    url: ENDPOINTS.getActiveAds,
  });
  return response;
};


export const getApprovedResearchByIdOnWebApi = async (id) => {
  let response = await axios({
    method: "get",
    url: `${ENDPOINTS.getApprovedResearchByIdOnWeb}/${id}`,
  });
  return response;
};


export const downloadCertificatePdfByUserApi = async (type, id) => {
  let response = await axios({
    method: 'get',
    url: `${ENDPOINTS.downloadCertificatePdfByUser}`,
    headers: commonHeaders(),
      params: {
        type, id,
      },
      responseType: 'blob', 
    });
  return response
}
export const getAllIndexingByUserApi = async () => {
  let response = await axios({
    method: "get",
    url: ENDPOINTS.getAllIndexingByUser,
    headers: commonHeaders(),
  });
  return response;
};
