import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { contactUsDataApi } from "../network/api";
import Testimonial from "../components/Testimonial";
import { ErrorMessage } from "@hookform/error-message";
import { useSelector, useDispatch } from "react-redux"; // Import useSelector and useDispatch
import { getWebSiteData } from "../redux/slices/websiteSlice"; // Import the action to load website data

const ContactPage = () => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);

  const contactEmail = useSelector((state) => state.website?.contactEmail);

  useEffect(() => {
    dispatch(getWebSiteData());
  }, [dispatch]);

  const onSubmit = async (data) => {
    setIsLoading(true);

    try {
      const response = await contactUsDataApi(data);
      if (response.status === 200 || response.data.status === 200) {
        toast.success(response.data.message);
        reset(); 
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error sending message:", error);
      toast.error("An error occurred while sending your message.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="container-fluid border rounded fw-bold">
        <h1 className="mt-3 mb-4 fontt">Contact Us Page</h1>
        <div className="container-fluid border rounded fw-bold mb-4"></div>
        <h6 className="mt-2">Contact The Maximus Report</h6>
        Mail: <a href={`mailto:${contactEmail}`}>{contactEmail}</a> {/* Display contactEmail from Redux */}
        <h6 className="mt-2 mb-4">Hours: 11 AM - 7 PM (Mon-Fri)</h6>

        <form onSubmit={handleSubmit(onSubmit)}>
          <h5 className="h5 mb-2 mt-5 fw-normal text-center fw-bold">
            Please fill the form in order to connect with us.
          </h5>

          <div className="form-floating mb-3">
            <input
              type="text"
              className={`form-control ${errors.name ? "danger-border" : ""}`}
              id="floatingInputName"
              {...register("name", { required: "This field is required" })}
              placeholder="Enter Your Name"
            />
            <label htmlFor="floatingInputName">
              Name <span className="text-danger">*</span>
            </label>
            <span className="error-text fw-normal">
              <ErrorMessage errors={errors} name="name" />
            </span>
          </div>

          <div className="form-floating mb-3">
            <input
              type="email"
              className={`form-control ${errors.email ? "danger-border" : ""}`}
              id="floatingInputEmail"
              {...register("email", {
                required: "This field is required",
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "Enter a valid email address",
                },
              })}
              placeholder="name@example.com"
            />
            <label htmlFor="floatingInputEmail">
              Email address <span className="text-danger">*</span>
            </label>
            <span className="error-text fw-normal">
              <ErrorMessage errors={errors} name="email" />
            </span>
          </div>

          <div className="form-floating mb-3">
            <textarea
              className={`form-control ${errors.typeMessage ? "danger-border" : ""}`}
              id="floatingTextarea2"
              {...register("typeMessage", { required: "typeMessage is required" })}
              placeholder="Leave a comment here"
              style={{ height: 100 }}
            ></textarea>
            <label htmlFor="floatingTextarea2">
              Type your message here… <span className="text-danger">*</span>
            </label>
            <span className="error-text fw-normal">
              <ErrorMessage errors={errors} name="typeMessage" />
            </span>
          </div>

          <button
            className="btn btn-primary w-100 py-2 mb-4 mt-4"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? "Sending..." : "Submit"}
          </button>
        </form>
      </div>
      <div className="mt-5">
        <Testimonial />
      </div>
    </>
  );
};

export default ContactPage;
