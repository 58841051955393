import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { createOrganizationDetailsApi } from '../network/api';
import Testimonial from '../components/Testimonial';

const GetFeaturedPage = () => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data) => {
    const startDate = new Date(data.start_date);
    const endDate = new Date(data.end_date);

    
    if (endDate < startDate) {
      toast.error("End date should be greater than start date.");
      return; 
    }
    const formPayload = new FormData();

  
    formPayload.append('company_name', data.company_name);
    formPayload.append('organizer_name', data.organizer_name);
    formPayload.append('contact_email', data.contact_email);
    formPayload.append('contact_number', data.contact_number);
    formPayload.append('company_address', data.company_address);
    formPayload.append('website_address', data.website_address);
    formPayload.append('advertisement_type', data.advertisement_type);
    formPayload.append('ad_placement_reference', data.ad_placement_reference);
    formPayload.append('ad_duration[start_date]', data.start_date);
    formPayload.append('ad_duration[end_date]', data.end_date);

    
    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput.files[0]) {
      const file = fileInput.files[0];
      const validFileTypes = ['image/jpeg', 'image/jpg', 'image/png'];
      const maxSizeInMB = 2;
      const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

      if (!validFileTypes.includes(file.type)) {
        toast.error('Only JPG and PNG images are allowed.');
        return;
      }

      if (file.size > maxSizeInBytes) {
        toast.error(`File size should not exceed ${maxSizeInMB}MB.`);
        return;
      }

      formPayload.append('image', file);
    }

    formPayload.append('redirected_url', data.redirected_url);
    formPayload.append('url', data.website_address === "yes" ? data.url : ''); 

    try {
      setIsLoading(true); 
      const response = await createOrganizationDetailsApi(formPayload);
      if (response.status === 200 || response.data.status === 200) {
        toast.success(response.data.message); // feature request submitted successfully
        reset(); 
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      toast.error("An error occurred while submitting the form.", error);
    } finally {
      setIsLoading(false); 
    }
  };

  return (
    <div className="container-fluid border rounded">
      <h1 className="mt-3 mb-4 fontt">Get Featured</h1>

      <p className="mt-2 ms-2">
        Here are some simple methods for advertising with us!
      </p>
      <div className="container-fluid">
        <ul>
          <li>Banner ads (top of the site)</li>
          <li>Full screen pop-up ad.</li>
        </ul>
        <p>
          Contact us at{" "}
          <a href="mailto:contact@themaximusreport.com">
            <strong>contact@themaximusreport.com</strong>
          </a>
          <strong>
            {" "}
            (with Subject line as - “Advertisement Inquiry - Company name”)
          </strong>
        </p>
      </div>

      <h6 className="mt-2 ms-2">Company/Organization Information:</h6>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-floating mb-3 ms-1">
          <input
            type="text"
            className="form-control"
            {...register("company_name", { required: true })}
            placeholder="Company Name"
          />
          <label>Company name <span className="text-danger">*</span></label>
          {errors.company_name && <span className="error-text">This field is required</span>}
        </div>

        <div className="form-floating mb-3 ms-1">
          <input
            type="text"
            className="form-control"
            {...register("organizer_name", { required: true })}
            placeholder="Contact Person Name"
          />
          <label>Contact person name <span className="text-danger">*</span></label>
          {errors.organizer_name && <span className="error-text">This field is required</span>}
        </div>

        <div className="form-floating mb-3 ms-1">
          <input
            type="email"
            className={`form-control ${errors.contact_email ? 'is-invalid' : ''}`}
            {...register("contact_email", { required: "Email is required",
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                message: "Invalid email address"}
             })}
            placeholder="Contact Email"
          />
          <label>Contact email <span className="text-danger">*</span></label>
          {errors.contact_email && <div className="error-text">{errors.contact_email.message}</div>} 
        </div>

        <div className="form-floating mb-3 ms-1">
          <input
            type="number"
            className={`form-control ${errors.contact_number ? 'is-invalid' : ''}`} 
            {...register("contact_number", { required: "Contact number is required",
              pattern: {
                value: /^[0-9]{10}$/,
                message: "Invalid contact number, should be 10 digits"}
             })}
            placeholder="Contact Number"
          />
          <label>Contact number <span className="text-danger">*</span></label>
          {errors.contact_number && <div className="error-text">{errors.contact_number.message}</div>}
        </div>

        <div className="form-floating mb-3 ms-1">
          <input
            type="text"
            className="form-control"
            {...register("company_address", { required: true })}
            placeholder="Company Address"
          />
          <label>Company address <span className="text-danger">*</span></label>
          {errors.company_address && <span className="error-text">This field is required</span>}
        </div>

        <div className="form-group mb-3 ms-1">
          <label>Do you have a website?</label>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              {...register("website_address")}
              id="yesOption"
              value="yes"
            />
            <label className="form-check-label" htmlFor="yesOption">
              Yes
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              {...register("website_address")}
              id="noOption"
              value="no"
              defaultChecked
            />
            <label className="form-check-label" htmlFor="noOption">
              No
            </label>
          </div>
        </div>

        {watch("website_address") === "yes" && (
          <div className="form-floating mb-3 ms-1">
            <input
              type="text"
              className="form-control"
              {...register("url", { required: watch("website_address") === "yes" })}
              placeholder="Enter your website URL"
            />
            <label>If Yes, enter the URL*</label>
            {errors.url && <span className="error-text">This field is required</span>}
          </div>
        )}

        <div className="">
          <h6 className="mt-2">Advertising Details:</h6>

          <div className="form-group mb-3 ms-1">


  <label>Type of advertisement</label>
  <div className="form-check">
    <input
      className="form-check-input"
      type="radio"
      {...register('advertisement_type', { required: true })}
      value="banner-ads"
              defaultChecked
            />
            <label className="form-check-label">Banner ads  <small className="text-muted">( Size: 600 x 400 pixels )</small></label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              {...register('advertisement_type')}
              value="pop-up-ads"
            /> 
            <label className="form-check-label">Pop-up ads <small className="text-muted">( Size: 400 x 300 pixels )</small></label>
          </div>
        </div>

          <div className="form-group mb-3 ms-1">
            <label>Ad placement preference</label>
            <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              {...register('ad_placement_reference', { required: true })}
              value="banner-ads"
              defaultChecked
            />
            <label className="form-check-label">Banner ads <small className="text-muted">( Size: 1440 x 200 pixels )</small></label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              {...register('ad_placement_reference')}
              value="pop-up-ads"
            />
            <label className="form-check-label">Pop-up ads <small className="text-muted">( Size: 400 x 300 pixels )</small></label>
          </div>
        </div>

        <div className="mb-3 ms-1">
  <label>Ad Duration:</label>


  
 <div className="row mb-3">
  <div className="col-md-6 form-floating">
    <input
      type="date"
      className="form-control"
      min={new Date(Date.now() + 2 * 24 * 60 * 60 * 1000).toISOString().split("T")[0]}
      {...register("start_date", {
        required: "This field is required",
        validate: (value) => {
          const selectedDate = new Date(value);
          const currentDate = new Date();
          currentDate.setDate(currentDate.getDate() + 2); 
          currentDate.setHours(0, 0, 0, 0); 
          return selectedDate >= currentDate || "Please select a date at least two days in the future";
        },
      })}
    />
    <label htmlFor="start_date">
      Start Date <span className="text-danger">*</span>
    </label>
    {errors.start_date && (
      <span className="error-text">{errors.start_date.message}</span>
    )}
  </div>

  



    <div className="col-md-6 form-floating">
      <input
        type="date"
        className="form-control"
        {...register("end_date", { required: true })}
      />
      <label htmlFor="end_date">End Date <span className="text-danger">*</span></label>
      {errors.end_date && <span className="error-text">This field is required</span>}
    </div>
  </div>
</div>


          <div className="mb-3 ms-1">
            <label>Upload Image <span className="text-danger">*</span></label>
            <input
              type="file"
              className="form-control"
              accept="image/jpeg, image/png"
            />
          </div>

          <div className="form-floating mb-3 ms-1">
  <input
    type="text"
    className="form-control"
    {...register("redirected_url", { required: false })}
    placeholder="Redirected URL"
  />
  <label>
    Redirected URL <span className="text-danger"></span>
  </label>
  {errors.redirected_url && (
    <span className="error-text">This field is required</span>
  )}
  <small className="text-muted">
    
    (Upon successful submission, we will reach out within 2 working days to discuss Breakouts and Expectations.)
  </small>
</div>


          <div className="text-center">
            <button type="submit" className="btn btn-primary w-100 mb-3" disabled={isLoading}>
              {isLoading ? 'Submitting...' : 'Submit'}
            </button>
          </div>
        </div>
      </form>
      <Testimonial />
    </div>



  );
};

export default GetFeaturedPage;
