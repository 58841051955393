import { Route, Routes, useLocation } from "react-router-dom";
import Footer from "./components/footer";
import Header from "./components/header";
import HomePage from "./pages/HomePage";
import LegalReportsPage from "./pages/LegalReportsPage";
import CuriosityPage from "./pages/CuriosityPage";
import YourStoryPage from "./pages/YourStoryPage";
import StudentCornerPage from "./pages/StudentsCornerPage";
import JobsInternshipsPage from "./pages/JobsInternshipsPage";
import GetFeaturedPage from "./pages/GetFeaturedPage";
import AboutPage from "./pages/AboutPage";
import ShowAllReseachPaper from "./pages/ShowAllReseachPaper";
import ContactPage from "./pages/ContactPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import TermsOfUsePage from "./pages/TermsOfUsePage";

import Carousel from "./components/Carousel";
import { useDispatch } from "react-redux";
import { getWebSiteData } from "./redux/slices/websiteSlice";
import ArticleDetailPage from "./pages/ArticleDetailPage";
import ReseachPaperDetailsPage from "./pages/ReseachPaperDetailsPage";
import { useEffect } from "react";
import ScrollToTop from "./components/ScrollToTop";
import { PageRoutes } from "./utils/enums";
import StudentArticleView from "./pages/student-corner/StudentArticleView";
import EditPostArticle from "./pages/student-corner/EditPostArticle";
import ResetPassword from "./pages/student-corner/ResetPassword";

import ResearchPaper from "./pages/student-corner/Researchpaper";
import UserProfileBar from "./components/UserProfileBar";
import Show404 from "./pages/Show404";
import ContentWrapper from "./ContentWrapper";
import VerificationComplete from "./pages/VerificationComplete";
import { showCarousel } from "./utils/appConfig";
import ManuScript from "./pages/ManuScript";
import SubmissionGuidelines from "./pages/SubmissionGuidelines";
import PublicationProcess from "./pages/PublicationProcess";
import ReviewProcess from "./pages/ReviewProcess";
import RefundCancellation from "./pages/RefundCancellation";

const MainSite = () => {
  const location =  useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getWebSiteData());
  }, []);
  return (
    <>
      <ScrollToTop />
      <Header />
      {showCarousel(location.pathname) && (
        <Carousel />
      )}
      <UserProfileBar />
      <ContentWrapper>
        <Routes>
          <Route path={PageRoutes.STARTUP_HUB} element={<HomePage />} />
          <Route
            path={PageRoutes.LEGAL_REPORT}
            element={<LegalReportsPage />}
          />
          <Route
            path={`${PageRoutes.CURIOSITY}/:hashtag?`}
            element={<CuriosityPage />}
          />
          <Route path={PageRoutes.YOUR_STORY} element={<YourStoryPage />} />
          <Route path="/article/:id" element={<ArticleDetailPage />} />
          <Route path="/user-article" element={<StudentArticleView />} />

          <Route path="/ResearchPaper" element={<ResearchPaper />} />

          {/* <Route path="/edit-article" element={<EditPostArticle />} /> */}

          <Route path="/reset-password/:token" element={<ResetPassword />} />

          <Route
            path="/verification-complete"
            element={<VerificationComplete />}
          />

          {/* <Route path="/article-detail" element={<ArticleDetailPage />} /> */}
          <Route
            path="/research-paper/:id"
            element={<ReseachPaperDetailsPage />}
          />
          <Route path="/student-corner/*" element={<StudentCornerPage />} />
          <Route
            path={PageRoutes.JOBS_INTERNSHIP}
            element={<JobsInternshipsPage />}
          />
          <Route path="/get-featured" element={<GetFeaturedPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route
            path={PageRoutes.RESERACH_PAPER}
            element={<ShowAllReseachPaper />}
          />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/terms-of-use" element={<TermsOfUsePage />} />
          <Route path="/manuscript" element={<ManuScript />} />
          <Route path="/submission-guidelines" element={<SubmissionGuidelines />} />
          <Route path="/publication-process" element={<PublicationProcess />} />
          <Route path="/review-process" element={<ReviewProcess />} />
          <Route path="/refund-cancellation" element={<RefundCancellation />} />
          <Route path="/*" element={<Show404 />} />
        </Routes>
      </ContentWrapper>

      <Footer />
    </>
  );
};
export default MainSite;
