import React, { useEffect, useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import {
  getResearchPaperByUserApi,
  downloadCertificatePdfByUserApi,
} from "../../network/api";

const ResearchPaper = () => {
  const [article, setArticle] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);

  const handleArticle = (article) => {
    if (article) {
      console.log("Article clicked:", article);
      console.log(article.research_status);
    }
  };

  const handleDownload = async (fileType, id) => {
    try {
      const response = await downloadCertificatePdfByUserApi(fileType, id);
      if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${fileType}_certificate_${id}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        toast.success("Certificate downloaded successfully");
      } else {
        toast.error("Failed to download file");
      }
    } catch (e) {
      toast.error(e.response?.data?.message ?? e.message);
    }
  };

  const determineFileType = (item) => {
    if (item.research_status === "approved") {
      return "publication"; 
    } else if (item.research_status === "rejected") {
      return "appreciation"; 
    }
    return null; 
  };

  const handleView = (article) => {
    setSelectedArticle(article);
    const modal = new window.bootstrap.Modal(
      document.getElementById("articleModal")
    );
    modal.show();
  };

  const fetchArticleDetails = async () => {
    setLoading(true);
    try {
      let response = await getResearchPaperByUserApi();
      if (response.status === 200 && response.data.status === 200) {
        const fetchedData = response.data.data;
        setArticle(Array.isArray(fetchedData) ? fetchedData : []);
      }
    } catch (e) {
      console.log("Fetch Error:", e);
      toast.error("Failed to load research papers.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchArticleDetails();
  }, []);

  return (
    <>
      <div className="row">
        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "50vh" }}
          >
            <div className="spinner-border text-dark" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : article.length === 0 ? (
          <div className="col-12">
            <p className="text-center">No research papers available.</p>
          </div>
        ) : (
          article.map((item) => (
            <div className="col-12" key={item._id}>
              <div className="card mb-3">
                <div className="card-body">
                  <h4 className="text-start">{item.research_paper_title}</h4>
                  <p className="text-start">{item.author_name}</p>
                  <p className="text-start">{item.area_of_research}</p>

                  <div className="text-start">
                    <div className="row">
                      <div className="col-sm-8 p-0">
                        <div className="text-start fw-light">
                          <div className="d-flex flex-wrap">
                            {item.research_status === "approved" && (
                              <>
                                <button
                                  type="button"
                                  className="btn btn-success me-2 mb-2"
                                  onClick={() => handleArticle(item)}
                                >
                                  <i className="bx bx-check-circle"></i> Approved
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-success me-2 mb-2"
                                  onClick={() =>
                                    handleDownload(determineFileType(item), item._id)
                                  }
                                >
                                  <i className="bx bx-download"></i> Certificate
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-primary me-2 mb-2"
                                  onClick={() => handleView(item)}
                                >
                                  <i className="bx bx-show"></i> View
                                </button>
                              </>
                            )}

                            {item.research_status === "rejected" && (
                              <>
                                <button
                                  type="button"
                                  className="btn btn-danger me-2 mb-2"
                                  onClick={() => handleArticle(item)}
                                >
                                  <i className="bx bx-x-circle"></i> Rejected
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-primary me-2 mb-2"
                                  onClick={() => handleView(item)}
                                >
                                  <i className="bx bx-show"></i> View
                                </button>
                                {/* <button
                                  type="button"
                                  className="btn btn-warning me-2 mb-2"
                                  onClick={() => handleArticle(item)}
                                >
                                  <i className="bx bx-edit"></i> Edit
                                </button> */}
                                {item.remarks && (
                                   <div className="col-12 p-1 mt-2 mb-2 text-danger">
                                   <span className="fst-italic">Reason:</span> 
                                   <span className="fw-bold" dangerouslySetInnerHTML={{ __html: item.remarks }}></span>
                                 </div>
                                )}
                              </>
                            )}

                            {item.research_status === "pending" && (
                              <>
                                <button
                                  type="button"
                                  className="btn btn-warning me-2 mb-2"
                                  onClick={() => handleArticle(item)}
                                >
                                  <i className="bx"></i> Pending
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-primary me-2 mb-2"
                                  onClick={() => handleView(item)}
                                >
                                  <i className="bx bx-show"></i> View
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-4">
                        <div className="text-end fs-6 fw-light">
                          <i className="bx bx-calendar fs-5 text-muted"></i>
                          <span className="ms-1">
                            {moment(item.updatedAt).format("MMM Do YY")}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>

      <div
  className="modal fade"
  id="articleModal"
  tabIndex="-1"
  aria-labelledby="articleModalLabel"
  aria-hidden="true"
>
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="articleModalLabel">
          Research Paper Details
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div className="modal-body">
        {selectedArticle && (
          <>
            <h5>{selectedArticle.research_paper_title ?? ""}</h5>
            <p>
              <strong>Status:</strong> {selectedArticle.research_status ?? ""}
            </p>
            <p>
              <strong>Author Name:</strong> {selectedArticle.author_name ?? ""}
            </p>
            <p>
              <strong>Email:</strong> {selectedArticle.author_email ?? ""}
            </p>
            <p>
              <strong>Submitted Date:</strong>{" "}
              {selectedArticle.createdAt
                ? new Date(selectedArticle.createdAt).toLocaleString()
                : "?"}
            </p>
            <p>
              <strong>Area of Research:</strong> {selectedArticle.area_of_research ?? ""}
            </p>

            {selectedArticle.research_status.toLowerCase() === "rejected" && (
              <p>
                <strong>Rejection Remarks:</strong> {selectedArticle.remarks ?? ""}
              </p>
            )}

            <p>
              <strong>Keywords:</strong> {selectedArticle.keywords ?? ""}
            </p>
            <p>
              <strong>Research Document:</strong>{" "}
              <a
                href={selectedArticle.research_doc ?? ""}
                target="_blank"
                rel="noopener noreferrer"
              >
                {selectedArticle.research_doc ? "View Document" : ""}
              </a>
            </p>
            <p>
              <strong>Last Updated:</strong>{" "}
              {selectedArticle.updatedAt
                ? new Date(selectedArticle.updatedAt).toLocaleString()
                : "?"}
            </p>

            {/* Author and Co-author Details */}
            <hr />
            <h6>Author Information</h6>
            <p>
              <strong>Institution:</strong> {selectedArticle.author_institution ?? ""}
            </p>
            <p>
              <strong>Contact:</strong> {selectedArticle.author_contact ?? ""}
            </p>
            <p>
              <strong>LinkedIn:</strong>{" "}
              <a
                href={selectedArticle.author_linkedin ?? ""}
                target="_blank"
                rel="noopener noreferrer"
              >
                {selectedArticle.author_linkedin ?? ""}
              </a>
            </p>

            {/* Co-author Details */}
            {selectedArticle.co_author_name1 && (
              <>
                <hr />
                <h6>Co-Author 1 Information</h6>
                <p>
                  <strong>Name:</strong> {selectedArticle.co_author_name1 ?? ""}
                </p>
                <p>
                  <strong>Email:</strong> {selectedArticle.co_author_email1 ?? ""}
                </p>
                <p>
                  <strong>Institution:</strong> {selectedArticle.co_author_institution1 ?? ""}
                </p>
                <p>
                  <strong>LinkedIn:</strong>{" "}
                  <a
                    href={selectedArticle.co_author_linkedin1 ?? ""}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {selectedArticle.co_author_linkedin1 ?? ""}
                  </a>
                </p>
              </>
            )}
            {selectedArticle.co_author_name2 && (
              <>
                <hr />
                <h6>Co-Author 2 Information</h6>
                <p>
                  <strong>Name:</strong> {selectedArticle.co_author_name2 ?? ""}
                </p>
                <p>
                  <strong>Email:</strong> {selectedArticle.co_author_email2 ?? ""}
                </p>
                <p>
                  <strong>Institution:</strong> {selectedArticle.co_author_institution2 ?? ""}
                </p>
                <p>
                  <strong>LinkedIn:</strong>{" "}
                  <a
                    href={selectedArticle.co_author_linkedin2 ?? ""}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {selectedArticle.co_author_linkedin2 ?? ""}
                  </a>
                </p>
              </>
            )}

            {/* Transaction Information */}
            <hr />
            <h6>Transaction Information</h6>
            {selectedArticle.transactions && selectedArticle.transactions.length > 0 ? (
              selectedArticle.transactions.map((transaction, index) => (
                <div key={index}>
                  <p>
                    <strong>Order ID:</strong> {transaction.orderId ?? ""}
                  </p>
                  <p>
                    <strong>Amount:</strong> {transaction.amount ? transaction.amount / 100 : ""}{" "}
                    {transaction.currency ?? ""}
                  </p>
                  <p>
                    <strong>Status:</strong> {transaction.transaction_status ?? ""}
                  </p>
                  <p>
                    <strong>Payment Receipt:</strong>{" "}
                    {transaction.razorpayOrderDetails ? transaction.razorpayOrderDetails.receipt : ""}
                  </p>
                  <p>
                    <strong>Transaction Date:</strong>{" "}
                    {transaction.createdAt
                      ? new Date(transaction.createdAt).toLocaleString()
                      : ""}
                  </p>
                </div>
              ))
            ) : (
              <p>No transaction details available.</p>
            )}
          </>
        )}
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-secondary"
          data-bs-dismiss="modal"
        >
          Close
        </button>
        {selectedArticle &&
          selectedArticle.research_status === "approved" && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={() =>
                handleDownload(
                  determineFileType(selectedArticle),
                  selectedArticle._id
                )
              }
            >
              Download Certificate
            </button>
          )}
      </div>
    </div>
  </div>
</div>

    </>
  );
};

export default ResearchPaper;
